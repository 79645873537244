.wrapper {
    .uploads {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 28px;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }

        :global(.ant-upload.ant-upload-select) {
            display: flex;
            justify-content: center;
        }

        :global(.ant-form-item-explain.ant-form-item-explain-connected) {
            left: calc(50% - 30px);
        }

        :global(.ant-upload-list-text-container) {
            max-width: 300px;
        }

        h6 {
            text-align: center;
            font-size: 15px;
            line-height: 15px;
        }

        label {
            font-size: 12px;
            line-height: 15px;
        }
    }

    .gdpr {
        :global(.ant-form-item-explain.ant-form-item-explain-connected) {
            left: 25px;
        }
    }
}
