@import '../../styles/vars.scss';

.pageWrapper {
    display: flex;
    align-items: flex-start;

    .navigationWrapper {
        max-width: 287px;
        margin-right: 34px;

        @media only screen and (max-width: 768px) {
            display: none;
        }

        h2 {
            text-transform: uppercase;
            font-family: 'Read Bold';
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 8px;
            // font-family: Read;
            // font-weight: normal;
            // font-size: 15px;
            // text-align: left;
            // line-height: 18px;
            // letter-spacing: 0px;
        }

        .formWrapper {
            :global(.ant-steps-item-process),
            :global(.ant-steps-item-finish) {
                border-left: 6px solid $brand-color;
                border-top: 1px solid $brand-color;
                border-bottom: 1px solid $brand-color;
                border-right: 1px solid $brand-color;

                &:hover {
                    cursor: pointer;
                }
            }

            :global(.ant-steps-item-active) {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);

                :global(.ant-steps-item-description) {
                    color: #000000;
                }
            }

            :global(.ant-steps-item-finish) {
                :global(.ant-steps-item-description) {
                    color: #000000;
                }
            }

            :global(.ant-steps-item-tail) {
                &:after {
                    width: 0 !important;
                }
            }
        }
    }

    .contentWrapper {
        // width: calc(100% - 287px - 34px);

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .error {
        color: #fff;
        padding: 12px 0;
        font-size: 11px;
        text-align: center;
        background-color: $error-color;
        filter: drop-shadow(0px 3px 6px rgba($error-color, 0.59));
    }

    .success {
        color: #fff;
        padding: 12px 0;
        font-size: 11px;
        text-align: center;
        background-color: $brand-color;
        filter: drop-shadow(0px 3px 6px rgba($brand-color, 0.59));
    }

    .sendButton {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            padding: 9px 60px;
            line-height: 21px;
        }
    }

    .guide {
        background-color: $step-background-completed;
        padding: 40px 52px;

        h2 {
            font-size: 16px;
            line-height: 15px;
        }

        a {
            color: $brand-color;
            text-decoration: underline;
        }
    }
}
