@import '../../styles/vars.scss';

.wrapper {
    max-width: 790px;
    padding: 40px 50px;
    margin-bottom: 40px;
    position: relative;

    @media only screen and (max-width: 768px) {
        padding: 40px 15px;
    }

    &.enabled {
        background-color: $step-background-completed;
    }

    &.disabled {
        opacity: 0.5;
    }

    &.completed {
        background-color: $step-background-completed;
    }

    .nextBtn {
        position: absolute;
        bottom: -16px;
        right: calc(50% - 18px);
        z-index: 1;
    }
}
