.wrapper {
    max-width: 310px;

    .from,
    .to {
        display: inline-block;
        width: calc(50% - 8px);
        max-width: 131px;

        @media only screen and (max-width: 768px) {
            max-width: initial;
        }

        :global(.ant-form-item-explain.ant-form-item-explain-connected) {
            width: 300px;
        }
    }

    .from {
        margin-bottom: 0;
    }

    .to {
        margin: 0 0 0 48px;
        @media only screen and (max-width: 768px) {
            margin: 0 8px;
        }
    }
}
