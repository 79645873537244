@import '../../styles/vars.scss';

.wrapper {
    .row {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .col {
        max-width: 310px;
        width: 50%;

        @media only screen and (max-width: 768px) {
            max-width: none;
            width: initial;
        }
    }

    :global(.ant-radio-group.ant-radio-group-solid) {
        width: 100%;

        label {
            width: 50%;
            text-align: center;
            outline: none;
            font-size: 14px;
            padding: 0;

            &:before {
                background-color: $brand-color;
            }
        }
    }

    :global(.ant-radio-button-wrapper) {
        border: 1px solid $brand-color;
    }

    .errorMessageDate {
        :global(.ant-form-item-explain.ant-form-item-explain-connected) {
            // top: -52px;

            // @media only screen and (max-width: 768px) {
            //     top: -33px;
            // }
        }
    }
}
